import VueRouter from 'vue-router'
const Home = ()=> import('@/pages/Home')
const About = ()=> import('@/pages/About')
const News = ()=> import('@/pages/News')
const NewsDet = ()=> import('@/pages/News/detail.vue')
const Download = ()=>  import('@/pages/Download')
const Innovation = ()=> import('@/pages/Innovation')
const Product = () => import('@/pages/Product')
const Module = () => import('@/pages/Product/module.vue')
const Dealer = () => import('@/pages/Product/dealer.vue')
const Solution = () => import('@/pages/Solution')
const Case = () => import('@/pages/Case')
const Sustain = () => import('@/pages/Sustain')

export default new VueRouter({
    mode:'history',
    routes:[
        {
            path: '/',
            component: Home
        },
        {
            path: '/about',
            component: About
        },
        {

            name:'news',
            path:'/news',
            component: News,
            props({query}){
                return { rmd: query.rmd, p:query.p }
            }
        },
        {
            path: '/newsdet',
            component: NewsDet,
            props({query}){
                return { id:query.id }
            }
        },
        {
            name: 'load',
            path: '/download',
            component: Download,
            props({query}){
                return { pid:query.pid, cid:query.cid, p:query.p }
            }
        },
        {
            path: '/innovation',
            component: Innovation
        },
        {
            path: '/product',
            component: Product,
            props({query}){
                return { id:query.id }
            }
        },
        {
            path:'/module',
            component: Module
        },
        {
            path:'/dealer',
            component: Dealer
        },
        {
            path: '/solution',
            component: Solution,
            props({query}){
                return{ id:query.id }
            }
        },
        {
            name:'case',
            path:'/case',
            component:Case,
            props({query}){
                return { p:query.p }
            }
        },
        {
            path:'/sustain',
            component: Sustain
        }
    ]
})
